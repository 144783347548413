.image {
  display: block;
  width: 100%;
  height: 12rem;
  -o-object-fit: cover;
     object-fit: cover;
  margin-bottom: 1rem;
}

.stats {
  display: flex;
  gap: 1rem;
}

.stats-item {
  display: grid;
  grid-template-columns: auto 1fr;
  -moz-column-gap: 0.5rem;
       column-gap: 0.5rem;
  align-items: center;
  margin-block: 1rem;

  > :first-child {
    grid-row: 1 / 3;
  }
}
