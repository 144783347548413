.item-grid {
  display: grid;
  grid-template-columns: auto 1fr auto;
  gap: 0.25rem;
  align-items: center;
}

.loading-list {
  list-style: none;
  margin: 0;
  padding: 0;
  display: grid;
  gap: 1rem;

  ol ~ & {
    margin-top: 1rem;
  }
}
