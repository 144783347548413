.uploadIcon {
  font-size: 4rem;
  color: var(--actionColor-onNeutral-primary);
  margin-bottom: 0.5rem;
}

.uploadedIcon {
  font-size: 3.313rem;
}

.integrations {
  text-align: center;
}

.integrations-logos {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1rem;

  img {
    display: block;
    height: auto;
  }
}

.garmin-logo {
  margin-inline: -1rem;
}

.wahoo-logo {
  width: 6rem;
  margin-bottom: 0.5rem;
}

.uploader-initial-content {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
