.illustration {
  width: 100%;
  margin-bottom: 2rem;
  padding: 2rem 3rem;
  background: var(--neutral-secondary);
  box-sizing: border-box;
  border-radius: 0.5rem;

  svg {
    width: 100%;
    height: auto;
  }
}
