@import 'shared/ui-components/scss/mixins.scss';

.list {
  list-style: none;
  margin: 0 0 1rem;
  padding: 0;
}

.item {
  display: flex;
  gap: 0.5rem;
  justify-content: stretch;
  align-items: center;

  > :first-child {
    flex-grow: 1;
  }
}

.item-loading {
  padding-block: 0.5rem;
}

.link {
  @include anchor;

  display: block;
  padding: 0.5rem;
  margin-left: -0.5rem;
  color: var(--onNeutral-primary);
  border-radius: var(--border-radius-medium);

  &:hover {
    color: var(--onNeutral-secondary);
    background-color: var(--neutral-secondary);
  }

  &:active {
    color: var(--onNeutral-primary);
    background-color: var(--neutral-tertiary);
  }
}
