.container {
  margin-bottom: 1rem;
}

.changed-label {
  float: right;
  color: var(--actionColor-onNeutral-primary);
  font-size: 0.75rem;
  margin: 0.125rem 0 0 0.5rem;
}
