.list {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: grid;
}

.item {
  touch-action: manipulation;
}

.drag-icon {
  display: flex;
  cursor: grab;
  color: var(--onNeutral-secondary);
}

.drag-icon-dragging {
  cursor: grabbing;
}

/* Prevent auto scrolling during dragging*/
*:has(.drag-icon-dragging) {
  overflow: hidden;
}
