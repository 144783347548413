@import '../../shared/scss/mixins';

.container {
  border-radius: var(--border-radius-small); /* noticeable with outline*/
  display: flex;
  align-items: center;
  gap: 0.5rem;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;

  &:not(.disabled) {
    cursor: pointer;

    &:hover,
    &:focus {
      .button {
        color: var(--neutral-quartery);
      }
    }
  }
}

.children {
  flex-grow: 1;
}

.button {
  flex-shrink: 0;
  line-height: 0;
  color: var(--neutral-tertiary);
  transition: color var(--transition-short);

  svg {
    vertical-align: baseline;
  }
}

.checkbox {
  @include visually-hidden;

  &:focus-visible + .container {
    @include highlighted-outline;
  }

  &:checked:not(:disabled) + .container {
    .button {
      color: var(--actionColor-primary);
    }

    &:hover,
    &:focus {
      .button {
        color: var(--actionColor-secondary);
      }
    }
  }
}
