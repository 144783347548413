$media-size: 4.5rem;

.container {
  position: relative;
  min-height: $media-size;
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 0.75rem;
  align-items: center;
}

.media {
  width: $media-size;
  height: $media-size;
  border-radius: var(--border-radius-medium);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  color: var(--onColor-primary);
  background-color: var(--neutral-tertiary);
}

.placeholder-icon {
  font-size: 2rem;
}

.image {
  display: block;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}

.content {
  min-width: 0;
}

.title {
  line-height: 1.25;
  margin-bottom: 0.25rem;
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow-wrap: break-word;

  &::after {
    content: '';
    position: absolute;
    inset: 0;
    border-radius: var(--border-radius-medium);
  }

  &:focus {
    outline: none;

    &::after {
      outline: solid var(--actionColor-primary);
    }
  }
}

.attributes {
  display: flex;
  flex-wrap: wrap;
  -moz-column-gap: 0.5rem;
       column-gap: 0.5rem;
  font-size: 0.75rem;
  color: var(--onNeutral-secondary);
  list-style: none;
  margin: 0;
}

.attribute-item {
  display: flex;
  gap: 0.25em;

  dt {
    span {
      visibility: hidden;
      display: inline-block;
      width: 0;
    }
  }

  dd {
    margin: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.location-attribute {
  width: 100%;
}
