.switch {
  margin: 0;
  display: flex;
  justify-content: space-between;
}

.contained {
  background-color: var(--neutral-secondary);
  border-radius: var(--border-radius-medium);
  padding-block: 0.3125rem;
  padding-left: 1rem;
}
