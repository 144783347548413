$gap: 0.5em;

.container {
  display: flex;
  align-items: center;
  height: 100%;
}

.images-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: $gap;
  width: 100%;

  &:not(.second-page) {
    & > .image:first-child {
      width: 100%;
    }
  }

  &.second-page {
    & > .image:last-child {
      width: 100%;
    }
  }
}

.image {
  display: block;
  width: calc(50% - #{$gap * 0.5});
  aspect-ratio: 1.6;
  border-radius: 0.25em;
  -o-object-fit: cover;
     object-fit: cover;
}
