.root {
  box-shadow: var(--box-shadow-08-dp);
  border-radius: 0.5rem;
  overflow: hidden;
  position: relative;
  padding: 1rem;

  h3,
  p {
    a {
      color: var(--onColor-primary);

      &:hover {
        color: var(--onColor-secondary);
      }

      &:active {
        color: var(--onColor-tertiary);
      }
    }
  }
}

.buttons {
  margin-top: 1rem;
}

.dismiss-button {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
}

@keyframes countDown {
  from {
    width: 100%;
  }
  to {
    width: 0;
  }
}

.progress {
  bottom: 0;
  left: 0;
  width: 100%;
  position: absolute;
  height: 5px;
  background-color: transparent;
}

.progress-bar {
  background-color: var(--onColor-secondary);
  animation-name: countDown;
  animation-timing-function: linear;
  animation-fill-mode: forwards;
  animation-duration: inherit;
}
