.content {
  padding: 1rem;
}

.with-close > :first-child {
  margin-right: 2.5rem;
}

.buttons {
  margin-top: 1rem;
}
