/* 1em = 1cm on printed page */

.root {
  width: 19em; /* without 1cm padding */
  height: 27.7em; /* without 1cm padding */
  display: grid;
  grid-template-rows: auto 1fr auto;
}

@media screen {
  .root {
    background-color: #fff;
    margin: 0 auto 1.5em;
    padding: 1em;
  }
}

@media print {
  .root {
    page-break-after: always;
    -moz-column-break-after: page;
         break-after: page;
  }
}

@page {
  size: A4 portrait;
}

@supports (hanging-punctuation: first) and (-webkit-appearance: none) {
  /* target Safari*/
  .root {
    height: 26.5em;
  }
}

.page-header {
  display: grid;
  grid-template-columns: auto 1fr auto;
  gap: 0.25em;
  align-items: center;
  margin: 0 0.5em 0.5em;
}

.title {
  font-size: 0.5em;
  font-weight: 500;
  margin: 0;
}

.location {
  font-size: 0.35em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0.2em 0 0;
}

.page-content {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.page-footer {
  display: grid;
  grid-template-columns: auto 1fr auto;
  gap: 0.25em;
  margin: 0.5em 0.5em 0;

  > p {
    font-size: 0.35em;
    margin: 0;
  }
}

.page-number {
  font-weight: 500;
}

.creator {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
