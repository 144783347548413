@import './definitions.scss';

.main {
  --web-app-sheet-width: 23.5rem;

  position: absolute;
  inset: 0;
  padding-top: var(--web-app-header-height);
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: stretch;
  background-color: var(--neutral-primary);
}

.snapping-container {
  overflow: hidden auto;
  flex-grow: 1;
  scroll-snap-type: y mandatory;
  scrollbar-width: none;
}

.full-screen-map-spacer,
.default-map-spacer {
  scroll-snap-align: start;
  position: relative;
}

.full-screen-map-spacer {
  height: calc(var(--web-app-sheet-default-fold) - var(--web-app-sheet-minimal-fold));
}

.default-map-spacer {
  height: var(--web-app-default-spacing-height);
}

.container {
  max-height: var(--web-app-container-height-sm);
  overflow: hidden;
  scroll-snap-align: end;
  margin-top: calc(var(--web-app-default-spacing-height) * -1);
  padding-top: calc(var(--web-app-default-spacing-height) - var(--web-app-sheet-handle-height));
}

.scrolling {
  overflow-y: auto;
}

@media (min-width: $layout-breakpoint) {
  .main {
    padding-top: 0;
  }

  .snapping-container {
    display: flex;
    flex-direction: column;
  }

  .container {
    max-height: none;
    flex-grow: 1;
    margin-top: 0;
    padding-top: 0;
    display: grid;
    grid-template-columns: 2rem var(--web-app-sheet-width) 1rem 1fr auto 2rem;
    grid-template-rows: 2rem min-content auto min-content 1fr 2rem auto;
    grid-template-areas:
      '.      .             .       .       .         .'
      '.      header        .       top     .         .'
      '.      sheet-content .       .       controls  .'
      '.      main-actions  .       .       controls  .'
      '.      .             .       .       controls  .'
      '.      .             .       .       .         .'
      'footer footer        footer  footer  footer    footer';
  }

  .full-screen-map-spacer,
  .default-map-spacer {
    display: none;
  }

  .non-snapping-container {
    overflow: visible;
  }

  .scrolling {
    overflow-y: hidden;
  }
}
