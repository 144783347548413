@import 'shared/ui-components/scss/mixins.scss';

$location-dot-size: 15px;

/*
Styles are partly taken from the MaplibreGl global styles, from which we unfortunately can't import specific parts.
https://github.com/maplibre/maplibre-gl-js/blob/main/src/css/maplibre-gl.css
*/

.root {
  height: 100%;

  :global(.maplibregl-map) {
    overflow: hidden;
    position: relative;
    -webkit-tap-highlight-color: rgb(0 0 0 / 0%);
  }

  :global(.maplibregl-canvas) {
    @include highlighted-outline-color;

    position: absolute;
    left: 0;
    top: 0;
  }

  :global(.maplibregl-canvas-container.maplibregl-interactive) {
    cursor: grab;
    -webkit-user-select: none;
       -moz-user-select: none;
            user-select: none;
  }

  :global(.maplibregl-canvas-container.maplibregl-interactive.maplibregl-track-pointer) {
    cursor: pointer;
  }

  :global(.maplibregl-canvas-container.maplibregl-interactive:active) {
    cursor: grabbing;
  }

  :global(.maplibregl-canvas-container.maplibregl-touch-zoom-rotate),
  :global(.maplibregl-canvas-container.maplibregl-touch-zoom-rotate .maplibregl-canvas) {
    touch-action: pan-x pan-y;
  }

  :global(.maplibregl-canvas-container.maplibregl-touch-drag-pan),
  :global(.maplibregl-canvas-container.maplibregl-touch-drag-pan .maplibregl-canvas) {
    touch-action: pinch-zoom;
  }

  :global(.maplibregl-canvas-container.maplibregl-touch-zoom-rotate.maplibregl-touch-drag-pan),
  :global(.maplibregl-canvas-container.maplibregl-touch-zoom-rotate.maplibregl-touch-drag-pan .maplibregl-canvas) {
    touch-action: none;
  }

  :global(.maplibregl-canvas-container.maplibregl-touch-drag-pan.maplibregl-cooperative-gestures),
  :global(.maplibregl-canvas-container.maplibregl-touch-drag-pan.maplibregl-cooperative-gestures .maplibregl-canvas) {
    touch-action: pan-x pan-y;
  }

  :global(.maplibregl-ctrl-top-left),
  :global(.maplibregl-ctrl-top-right),
  :global(.maplibregl-ctrl-bottom-left),
  :global(.maplibregl-ctrl-bottom-right) {
    display: none;
  }

  :global(.maplibregl-popup) {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    will-change: transform;
    pointer-events: none;
  }

  :global(.maplibregl-popup-anchor-top),
  :global(.maplibregl-popup-anchor-top-left),
  :global(.maplibregl-popup-anchor-top-right) {
    flex-direction: column;
  }

  :global(.maplibregl-popup-anchor-bottom),
  :global(.maplibregl-popup-anchor-bottom-left),
  :global(.maplibregl-popup-anchor-bottom-right) {
    flex-direction: column-reverse;
  }

  :global(.maplibregl-popup-anchor-left) {
    flex-direction: row;
  }

  :global(.maplibregl-popup-anchor-right) {
    flex-direction: row-reverse;
  }

  :global(.maplibregl-popup-tip) {
    width: 0;
    height: 0;
    border: 10px solid transparent;
    z-index: 1;
  }

  :global(.maplibregl-popup-anchor-top .maplibregl-popup-tip) {
    align-self: center;
    border-top: none;
    border-bottom-color: var(--neutral-primary);
  }

  :global(.maplibregl-popup-anchor-top-left .maplibregl-popup-tip) {
    align-self: flex-start;
    border-top: none;
    border-left: none;
    border-bottom-color: var(--neutral-primary);
  }

  :global(.maplibregl-popup-anchor-top-right .maplibregl-popup-tip) {
    align-self: flex-end;
    border-top: none;
    border-right: none;
    border-bottom-color: var(--neutral-primary);
  }

  :global(.maplibregl-popup-anchor-bottom .maplibregl-popup-tip) {
    align-self: center;
    border-bottom: none;
    border-top-color: var(--neutral-primary);
  }

  :global(.maplibregl-popup-anchor-bottom-left .maplibregl-popup-tip) {
    align-self: flex-start;
    border-bottom: none;
    border-left: none;
    border-top-color: var(--neutral-primary);
  }

  :global(.maplibregl-popup-anchor-bottom-right .maplibregl-popup-tip) {
    align-self: flex-end;
    border-bottom: none;
    border-right: none;
    border-top-color: var(--neutral-primary);
  }

  :global(.maplibregl-popup-anchor-left .maplibregl-popup-tip) {
    align-self: center;
    border-left: none;
    border-right-color: var(--neutral-primary);
  }

  :global(.maplibregl-popup-anchor-right .maplibregl-popup-tip) {
    align-self: center;
    border-right: none;
    border-left-color: var(--neutral-primary);
  }

  :global(.maplibregl-popup-content) {
    position: relative;
    background: var(--neutral-primary);
    border-radius: 3px;
    box-shadow: var(--box-shadow-04-dp);
    padding: 0.75rem 1rem 1rem;
    pointer-events: auto;
  }

  :global(.maplibregl-popup-anchor-top-left .maplibregl-popup-content) {
    border-top-left-radius: 0;
  }

  :global(.maplibregl-popup-anchor-top-right .maplibregl-popup-content) {
    border-top-right-radius: 0;
  }

  :global(.maplibregl-popup-anchor-bottom-left .maplibregl-popup-content) {
    border-bottom-left-radius: 0;
  }

  :global(.maplibregl-popup-anchor-bottom-right .maplibregl-popup-content) {
    border-bottom-right-radius: 0;
  }

  :global(.maplibregl-popup-track-pointer) {
    display: none;
  }

  :global(.maplibregl-popup-track-pointer *) {
    pointer-events: none;
    -webkit-user-select: none;
       -moz-user-select: none;
            user-select: none;
  }

  :global(.maplibregl-map:hover .maplibregl-popup-track-pointer) {
    display: flex;
  }

  :global(.maplibregl-map:active .maplibregl-popup-track-pointer) {
    display: none;
  }

  :global(.maplibregl-marker) {
    position: absolute;
    top: 0;
    left: 0;
    will-change: transform;
  }

  :global(.maplibregl-user-location-dot) {
    width: $location-dot-size;
    height: $location-dot-size;
    border-radius: 50%;
    background: var(--actionColor-primary);

    &::before {
      background: var(--actionColor-secondary);
      content: '';
      width: $location-dot-size;
      height: $location-dot-size;
      border-radius: 50%;
      position: absolute;
      animation: maplibregl-user-location-dot-pulse 2s infinite;
    }
  }

  :global(.maplibregl-user-location-dot::after) {
    border-radius: 50%;
    border: 2px solid var(--neutral-primary);
    content: '';
    position: absolute;
    left: -2px;
    top: -2px;
    width: $location-dot-size + 4px;
    height: $location-dot-size + 4px;
    box-sizing: border-box;
    box-shadow: var(--box-shadow-04-dp);
  }

  @keyframes maplibregl-user-location-dot-pulse {
    0% {
      transform: scale(1);
      opacity: 1;
    }
    70% {
      transform: scale(3);
      opacity: 0;
    }
    100% {
      transform: scale(1);
      opacity: 0;
    }
  }

  :global(.maplibregl-user-location-dot-stale) {
    background: var(--actionColor-inactive);
  }

  :global(.maplibregl-user-location-dot-stale::after) {
    display: none;
  }

  :global(.maplibregl-user-location-accuracy-circle) {
    background: #0873e715;
    width: 1px;
    height: 1px;
    border-radius: 100%;
  }
}
