@import './definitions.scss';

.container {
  position: absolute;
  pointer-events: none;
  z-index: $z-index-controls;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  top: calc(var(--web-app-header-height) + 1rem);
  right: 1rem;
  gap: 0.5rem;

  > * {
    pointer-events: auto;
  }
}

@media (min-width: $layout-breakpoint) {
  .container {
    position: relative;
    grid-area: controls;
    inset: 0;
    align-self: end;
    gap: 1rem;
  }
}
