@import 'shared/ui-components/scss/mixins.scss';

.root {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.media {
  position: relative;
  width: 3rem;
  height: 3rem;
  flex-shrink: 0;

  &::before,
  &::after {
    content: '';
    display: block;
    border-radius: var(--border-radius-medium);
    position: absolute;
  }

  &::before {
    background-color: var(--neutral-tertiary);
    inset: 0 0.5rem;
  }

  &::after {
    background-color: var(--neutral-secondary);
    inset: 0.25rem 0 0;
  }
}

.media-skeleton,
.image {
  position: absolute;
  z-index: 1;
  border-radius: var(--border-radius-medium);
  top: 0.25rem;
  width: 100%;
  height: calc(100% - 0.25rem);
}

.image {
  display: block;
  overflow: hidden;
  -o-object-fit: cover;
     object-fit: cover;
}

.icon {
  position: absolute;
  z-index: 1;
  color: var(--onNeutral-tertiary);
  inset: 0.75rem;
}

.text {
  min-width: 0;
  flex-grow: 1;
}

.title {
  @include one-line-truncated-text;
}
