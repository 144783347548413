@import './definitions.scss';

.container {
  position: fixed;
  left: 50%;
  transform: translate(-50%, -0.75rem);
  z-index: $z-index-scroll-tooltip;

  &:after {
    content: '';
    position: absolute;
    width: 0.5rem;
    height: 0.5rem;
    transform: rotate(45deg);
    top: 100%;
    left: 50%;
    background: var(--actionColor-primary);
    z-index: -1;
    margin: -0.25rem 0 0 -0.25rem;
  }

  &:hover:after {
    background: var(--actionColor-secondary);
  }
}

@media (min-width: $layout-breakpoint) {
  .container {
    position: absolute;
  }
}
