.root {
  background-color: var(--coloredBG-secondaryBlue700);
  height: 100%;
  overflow-y: auto;
  padding: 3.5rem 1rem;
}

.root-large {
  padding: 5.5rem max(2rem, 20vw - 8rem) 0 27.5rem;
}

.pages-container {
  font-size: 2.25rem; /* 1cm on paper */
  padding-bottom: 3.5rem;
}

.pages-container-small {
  transform-origin: top;
  margin-left: 50%;
  position: relative;
  left: -10.5em;
  width: fit-content;
  transform: scale(0.45);
}

.pages-container-large {
  transform-origin: top left;
}

.print-pages-container {
  font-size: 1cm;
}

@media (max-width: 84rem) {
  .pages-container-large {
    transform: scale(0.8);
  }
}

@media (max-width: 67rem) {
  .pages-container-large {
    transform: scale(0.5);
  }
}

@media (max-width: 53rem) {
  .pages-container-large {
    transform: scale(0.4);
  }
}
