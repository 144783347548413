.header {
  position: relative;
  height: 12rem;

  img {
    display: block;
    height: 100%;
    -o-object-fit: cover;
       object-fit: cover;
  }
}

.content {
  padding: 1.5rem 1rem 1rem;
  text-align: center;
}

.qr-code-holder {
  background-color: var(--accentColor-primary);
  border-radius: var(--border-radius-large);
  padding: 0.5rem;
  width: 7.25rem;
  height: 7.25rem;
  margin: 1rem auto;
}

.qr-code {
  display: block;
  width: 6.25rem;
  height: 6.25rem;
  margin: 0.5rem;
}
