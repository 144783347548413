.wrapper {
  padding: 1rem 1rem 0.5rem;
}

.heading {
  padding-top: 0.5rem;
}

.heading-secondary {
  padding-top: 0;
}

.list {
  list-style-type: none;
  padding: 0.5rem 0;
  margin: 0;
}
