@import '../../shared/scss/mixins';

$avatar-size: 3em;

.root {
  position: relative;
  width: $avatar-size;
  height: $avatar-size;
  pointer-events: none;
}

.premium-badge {
  position: absolute;
  right: -0.1em;
  bottom: -0.1em;
}

.img-container {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: var(--neutral-secondary);
  color: var(--onNeutral-tertiary);
  border-radius: 50%;
  box-sizing: border-box;
}

.image {
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  font-size: 0;
}

.skeleton {
  width: $avatar-size;
  height: $avatar-size;
}

.close-button {
  width: $avatar-size;
  height: $avatar-size;
  font-size: inherit;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
}
