@import '../../shared/scss/mixins';

.select {
  @include visually-hidden;
}

.label {
  @include field-label;

  margin-bottom: 0.25rem;
}

.invisible-label {
  @include visually-hidden;
}

.chips {
  border: 1px solid var(--neutral-secondary);
  border-radius: var(--border-radius-medium);
  padding: 0.3125rem 0.5rem;
  display: flex;
  gap: 0.5rem;
  flex-wrap: wrap;
}
