.images-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 0.5rem;
}

.button {
  position: relative;
  padding: 0;
  border: 1px solid var(--neutral-secondary);
  border-radius: var(--border-radius-small);
  aspect-ratio: 1.3;
  overflow: hidden;

  &:not(:disabled) {
    cursor: pointer;

    &:hover,
    &:focus {
      border-color: var(--actionColor-primary);
    }

    &:active {
      border-color: var(--actionColor-secondary);
    }
  }
}

.image {
  display: block;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}

.indicator {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  text-align: center;
  line-height: 1.25rem;
  font-weight: 500;
  height: 1.25rem;
  width: 1.25rem;
  border-radius: 50%;
  background-color: var(--actionColor-inactive);
  border: 1px solid var(--actionColor-onNeutral-inactive);
}

.indicator-selected {
  background-color: var(--actionColor-primary);
  border: 1px solid var(--actionColor-onNeutral-secondary);
  color: var(--onColor-primary);
}
