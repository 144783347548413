.root {
  position: absolute;
  width: min(100% - 4rem, 23rem);
  inset: auto 1rem 100%;
  border-radius: var(--border-radius-large);
  background: var(--neutral-primary);
  box-shadow: var(--box-shadow-04-dp);
  padding: 1rem;
  margin: 0 auto 1rem;
  max-height: calc(
    var(--web-app-default-spacing-height) - var(--web-app-sheet-handle-height) - 3rem
  ); /* so that it stays within the area defined by padding-top */
  overflow: hidden auto;
}
