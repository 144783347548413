@import 'shared/ui-components/scss/mixins.scss';

.tag {
  @include one-line-truncated-text;

  padding: 0.25rem 0.5rem;
  border-radius: var(--border-radius-small);
  font-size: 0.75rem;
  font-weight: 500;
  text-transform: uppercase;
  border: none;
  font-family: inherit;
  line-height: 1.5;
  box-sizing: border-box;
  flex-shrink: 0;
}

button.tag {
  cursor: pointer;
}

.default {
  background-color: var(--neutral-secondary);
  color: var(--onNeutral-primary);
}

.primary {
  background-color: var(--actionColor-primary);
  color: var(--onColor-primary);
}

.dark {
  background-color: var(--onNeutral-primary);
  color: var(--onColor-primary);
}
