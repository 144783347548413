$column-gap: 0.5rem;

.container {
  padding: 1rem;
  display: grid;
  grid-template-columns: auto 1fr auto;
  grid-template-areas:
    'before input after'
    'suggestions suggestions suggestions';
  -moz-column-gap: $column-gap;
       column-gap: $column-gap;
  align-items: center;

  &.has-before > :first-child {
    grid-area: before;
  }

  &.has-after .input + * {
    grid-area: after;
  }
}

.input {
  grid-area: input;

  &:first-child {
    margin-left: -$column-gap;
  }
}
