.privacy-item {
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  gap: 0.5rem;
}

.privacy-item-icon {
  color: var(--actionColor-onNeutral-primary);
}

.privacy-item-icon > svg {
  display: block;
}
