.root {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  padding: 1rem;
  transform: translateY(0);
  transition: transform var(--transition-long);
  z-index: 120; /* large screen card has z-index: 100*/
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.hidden {
  transform: translateY(calc(100% + 2rem));
}
