.heading {
  display: flex;
  gap: 0.5rem;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;

  h2 {
    margin-bottom: 0;
  }
}

.premium-info {
  font-size: 0.75rem;

  svg {
    vertical-align: sub;
    font-size: 1.25em;
    margin-left: 0.25em;
  }
}

.buttons {
  display: grid;
  gap: 0.5rem;
  grid-template-columns: min-content 1fr 1fr;
}
