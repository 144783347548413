.container {
  position: relative;

  img {
    width: 100%;
    height: auto;
    display: block;
  }
}

.premium-badge {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 50%);
  font-size: 3rem;
}

.badge {
  text-transform: uppercase;
  background-color: var(--accentColor-primary);
  color: var(--onColor-primary);
  font-weight: var(--font-weight-medium);
  border-radius: 0.75rem;
  padding: 0 0.5rem;
  position: absolute;
  top: 1rem;
  left: 1rem;
  width: -moz-fit-content;
  width: fit-content;
}
