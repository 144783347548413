.wrapper {
  padding: 1rem 1rem 0.5rem 1rem;

  + .wrapper {
    padding-top: 0;
  }

  &:last-child {
    padding-bottom: 1rem;
  }
}
