@import '../../shared/scss/mixins';

.root {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  background-color: var(--neutral-secondary);
  padding: 0.312rem 1rem;
  font-size: 0.75rem;
  -moz-column-gap: 1rem;
       column-gap: 1rem;
}

.root-compact {
  padding: 0.125rem 0.5rem 0.125rem 1rem;
}

.colored {
  background-color: var(--coloredBG-secondaryBlue500);
  color: var(--onColor-primary);
}

.text {
  color: var(--onNeutral-secondary);

  a {
    @include anchor;
    @include anchor-secondary;
    @include anchor-small;
  }
}

.popover {
  padding: 0.25rem 0.5rem;
}

.hidden {
  opacity: 0;
  position: absolute;
  pointer-events: none;
}
