.tour-tag {
  position: relative;
  display: flex;
  align-items: center;
  gap: 0.25rem;
}

.tour-tag-info-link {
  margin-block: -0.25rem;

  svg {
    font-size: 1rem;
  }
}

.tooltip {
  background-color: var(--notificationComp-info);
  color: var(--onColor-primary);
  padding: 1rem 1rem 0.75rem;
  border-radius: var(--border-radius-medium);
  max-width: 19.5rem;
  margin-top: 0.5rem;
}

.close-button {
  float: right;
  margin: -0.5rem -0.5rem 0 0;
}

.tooltip-buttons {
  display: flex;
  justify-content: end;
}
