.heading {
  background-color: var(--neutral-primary);
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 0.5rem;
  align-items: center;
  min-height: 1.625rem; /* to prevent layout shift when buttons are hidden */
}

.actions {
  display: flex;
  gap: 0.5rem;
}
