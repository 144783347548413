@import './definitions.scss';

.navigation {
  display: none;
  align-self: stretch;
  align-items: center;

  &:has(.items-small) {
    display: flex;
  }
}

.items-small {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.items-large {
  display: none;
}

@media (min-width: $navigation-breakpoint) {
  .navigation {
    position: fixed;
    top: 2rem;
    right: 2rem;
    background-color: var(--neutral-primary);
    box-shadow: var(--box-shadow-04-dp);
    border-radius: var(--border-radius-large);
    border-top-right-radius: 2rem;
    border-bottom-right-radius: 2rem;
    padding: 0.5rem;
    display: flex;
    align-items: center;
    width: -moz-fit-content;
    width: fit-content;
    white-space: nowrap;
    gap: 0.5rem;

    &:has(.items-large) {
      display: flex;
    }
  }

  .items-small {
    display: none;
  }

  .items-large {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
}
