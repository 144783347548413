.button {
  position: fixed;
  bottom: 2rem;
  left: 50%;
  transform: translateX(-50%) scale(0);
  opacity: 0;
  z-index: 13;
  transition: opacity var(--transition-long) ease;
}

.visible {
  transform: translateX(-50%);
  opacity: 1;
}
