.title {
  margin-bottom: 0.25rem;
  overflow-wrap: break-word;
}

.secondary-info {
  &:not(:last-child) {
    margin-right: 0.75rem;
  }

  .secondary-info-icon {
    font-size: 1.5em;
    vertical-align: top;
  }
}

.floating-container {
  float: right;
  padding-left: 0 0 0.25rem 0.5rem;
}
