$closed-padding: 0.5rem;

.bottom-sheet {
  border-radius: var(--border-radius-large) var(--border-radius-large) 0 0;
  box-shadow: var(--box-shadow-base-light);
  background-color: var(--neutral-primary);
  padding: 1.5rem 1rem 1rem;

  &:before {
    content: '';
    position: absolute;
    z-index: 1;
    top: 0.75rem;
    left: calc(50% - 1.5rem);
    width: 3rem;
    height: 0.25rem;
    border-radius: 0.125rem;
    background: var(--neutral-tertiary);
  }
}

.open,
.closed {
  position: relative;
  background: var(--neutral-primary);
  box-shadow: var(--box-shadow-04-dp);
}

.open {
  padding: 1rem;
  width: 22rem;
  max-height: 70vh;
  overflow-y: auto;
  border-radius: var(--border-radius-large);
}

.close-button-holder {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
}

.closed {
  padding: $closed-padding;
  margin-right: -$closed-padding;
  display: flex;
  align-items: center;
  border-radius: var(--border-radius-medium);
}

.label {
  margin: 0 0.5rem;
}
