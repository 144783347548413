.list-small-viewport {
  padding-bottom: 1rem;
}

.list-large-viewport {
  padding-top: 0.5rem;
  padding-bottom: 1rem;
}

.list-large-viewport-initial-with-results {
  padding-bottom: 0;
}

.buttons {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  padding: 0 1rem 1rem 3rem;
}
