.root {
  &:not(:last-child) {
    margin-bottom: 1rem;
  }

  input[type='radio'] {
    &:focus + label {
      border-color: var(--onNeutral-secondary);
      background-color: var(--neutral-secondary);

      & > div:first-child {
        border-color: var(--onNeutral-secondary);
      }
    }

    &:checked + label {
      background-color: var(--neutral-secondary);
      border-color: var(--actionColor-primary);

      & > div:first-child {
        border-color: var(--actionColor-primary);

        div {
          opacity: 1;
        }
      }
    }
  }

  label {
    padding: 1rem;
    padding-left: 3rem;
    border: 1px solid var(--onNeutral-tertiary);
    border-radius: var(--border-radius-medium);
    transition-property: border-color, background-color;
    transition-duration: var(--transition-short);

    &:hover,
    &:focus {
      border-color: var(--onNeutral-secondary);
      background-color: var(--neutral-secondary);

      & > div:first-child {
        border-color: var(--onNeutral-secondary);
      }
    }

    & > div:first-child {
      left: 1rem;
    }
  }
}

.label {
  font-size: 1rem;
}

.description {
  font-size: 0.75rem;
}

.image {
  margin-bottom: 0.5rem;
  border-radius: var(--border-radius-small);
  align-self: flex-start;
  overflow: hidden;

  > * {
    display: block;
    width: 100%;
  }
}

.compactRoot {
  label {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 0.7rem 0.6rem 0.6rem 2.4rem;

    & > div:first-child {
      top: 0.8rem;
      left: 0.7rem;
      transform: none;
    }
  }
}

.compactContent {
  display: flex;
  width: 100%;
  margin: 0.5rem 0 0 -1.7rem;
}

.compactImage {
  width: 5rem;
  height: 5rem;
  margin: 0 0.5rem 0 0;
  flex-shrink: 0;
}
