.container {
  position: relative;

  &::before,
  &::after {
    content: '';
    position: absolute;
    inset-block: 0;
    width: 5rem;
    pointer-events: none;
    opacity: 0;
    transition: opacity var(--transition-long) ease;
  }

  &::before {
    left: 0;
    background-image: linear-gradient(to left, #fff0, #fffc, var(--neutral-primary));
    z-index: 1;
  }

  &::after {
    right: 0;
    background-image: linear-gradient(to right, #fff0, #fffc, var(--neutral-primary));
  }

  &.scrollable-back::before {
    opacity: 1;
  }

  &.scrollable::after {
    opacity: 1;
  }
}

.list {
  list-style: none;
  display: flex;
  gap: 0.5rem;
  padding: 0.25rem 1rem 1rem;
  margin: 0 0 -0.25rem;
  overflow-x: auto;
  scrollbar-width: none;
}

.scroll-back-button,
.scroll-button {
  position: absolute;
  top: 0.125rem;
  z-index: 1;
  transition: all var(--transition-long) ease;
}

.scroll-back-button {
  left: 0.25rem;
}

.scroll-button {
  right: 0.25rem;
}

.hidden {
  opacity: 0;
  transform: scale(0);
}

@supports (-webkit-touch-callout: none) {
  .scroll-back-button,
  .scroll-button {
    display: none;
  }
}
