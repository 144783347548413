@mixin smaller {
  font-size: 0.75rem;
  border-radius: 0.25rem;
  font-weight: var(--font-weight-medium);

  &:not(.iconOnly) {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
}

.default {
  --button-padding-v: 0.75rem;
  --button-padding-h: 1rem;

  font-size: 1rem;
  font-weight: 700;
  border-radius: 0.5rem;
}

.medium {
  @include smaller;

  --button-padding-v: 0.4375rem;
  --button-padding-h: 0.4375rem;

  &.iconOnly {
    min-width: 2rem;
  }
}

.small {
  @include smaller;

  --button-padding-v: 0.25rem;
  --button-padding-h: 0.25rem;

  &.iconOnly {
    min-width: 1.625rem;
  }
}

.icon {
  display: inherit;
  margin-right: 0.25em;
  margin-left: -0.25em;

  svg {
    font-size: 1.5em;
  }
}

.iconOnly {
  min-width: 3rem;
  flex: 0;
  --button-padding-h: 0;
}

.iconOnlyIcon {
  margin: 0;
}

.block {
  display: flex;
  width: 100%;
}

.with-premium-lock:not(.iconOnly) {
  padding-right: 1.75rem;
}

.premium-lock {
  position: absolute;
  right: 0.5rem;
  font-size: 0.8rem;
}
