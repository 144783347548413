@import '../../shared/scss/mixins';

.anchor {
  @include anchor;
  font-family: var(--font-family-default);
  vertical-align: unset;
  font-size: inherit; /* in case it is a button*/

  > svg {
    font-size: 1.25rem;
    vertical-align: -0.25rem;
  }
}

.secondary {
  @include anchor-secondary;
}

.small {
  @include anchor-small;
}

.on-color {
  color: var(--onColor-primary);

  &:focus,
  &:hover {
    color: var(--onColor-secondary);
  }

  &:active {
    color: var(--onColor-tertiary);
  }
}
