@import '../../shared/scss/mixins';

.root {
  margin-inline-start: 0;
  margin-inline-end: 0;
  padding-block-start: 0;
  padding-inline-start: 0;
  padding-inline-end: 0;
  padding-block-end: 0;
  min-inline-size: initial;
  border: 0;
}

.wrapper {
  position: relative;
}

.bordered {
  padding: 0.6875rem 0.5rem;
  border: 1px solid var(--neutral-secondary);
  border-radius: var(--border-radius-medium);
  transition: border-color var(--transition-short);
}

.label {
  @include field-label;

  padding-inline: 0;
  margin-bottom: 0.25rem;
}
