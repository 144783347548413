@import '../../shared/scss/mixins';

.tooltip {
  position: relative;
  z-index: 10000;

  .tooltiptext {
    min-width: 60px;
    background-color: var(--actionColor-primary);
    color: #fff;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    padding: 8px;
    z-index: 1;
    transform: translate(-50%, -60%);
    white-space: nowrap;
  }

  .tooltiptext-long {
    min-width: 120px;
  }

  .tooltiptext::after {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -8px;
    border-width: 8px;
    border-style: solid;
    border-color: var(--actionColor-primary) transparent transparent transparent;
  }
}

.section-tooltiptext {
  display: inline-flex;
  align-items: center;
  white-space: nowrap;
  padding: 0 4px;
}
