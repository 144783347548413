$button-group-gap: 0.5rem;

.root {
  display: flex;
  gap: $button-group-gap;
  justify-content: stretch;
  flex-wrap: wrap;

  > * {
    flex: auto;
    min-width: calc(50% - $button-group-gap);
  }
}

.stack {
  flex-direction: column;
}
