.label {
  position: absolute;
  top: 6px;
  left: 0;
  right: 0;
  text-align: center;
  font-weight: bold;
  font-size: 12px;
  line-height: 1;
  color: var(--onNeutral-primary);
}

.label-large {
  top: 8px;
  font-size: 20px;
}
