.container {
  border: 1px solid var(--onNeutral-secondary);
  border-radius: 0.25em;
  width: 2.75em;
  padding: 0.25em;
}

.container p {
  font-size: 0.35em;
  text-align: center;
  margin: 0 0 1em;
}
