@import '../../shared/scss/mixins';

.container {
  padding: 0.5rem;
  padding-left: 2rem;
  border-radius: var(--border-radius-small); /* noticeable with outline*/
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  cursor: pointer;

  &:hover,
  &:focus {
    .button {
      border-color: var(--onNeutral-tertiary);
    }

    span:last-child {
      color: var(--onNeutral-secondary);
    }
  }
}

.button {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: calc(1rem + 2px);
  height: calc(1rem + 2px);
  border-radius: 100%;
  border: 1px solid var(--onNeutral-tertiary);
  transition: border-color var(--transition-short);
}

.selector {
  background-color: var(--actionColor-primary);
  width: calc(1rem - 4px);
  height: calc(1rem - 4px);
  border-radius: 100%;
  border: 3px solid var(--neutral-primary);
  opacity: 0;
  transition-property: opacity, background-color;
  transition-duration: var(--transition-short);
}

.radio {
  @include visually-hidden;

  &:focus-visible + .container {
    @include highlighted-outline;
  }

  &:checked + .container {
    .button {
      border-color: var(--actionColor-primary);

      .selector {
        opacity: 1;
      }
    }

    &:hover,
    &:focus {
      .button {
        border-color: var(--actionColor-secondary);

        .selector {
          background-color: var(--actionColor-secondary);
        }
      }
    }
  }
}
