@import './definitions.scss';

.container {
  position: relative;
  background-color: var(--neutral-primary);

  &:first-child {
    border-radius: var(--border-radius-large) var(--border-radius-large) 0 0;
    padding-top: var(--web-app-sheet-handle-height);

    &::before {
      top: var(--web-app-sheet-handle-height);
    }
  }
}

.secondary {
  background-color: var(--neutral-secondary);
}

.padding {
  padding: 0.75rem 1rem;
  display: grid;
  grid-template-columns: 1fr [content] min(100%, 23rem) 1fr;

  .paddingless-child {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    width: 100vw;

    &:first-child {
      margin-top: -0.75rem;
    }

    &:last-child {
      margin-bottom: -0.75rem;
    }
  }

  &:first-child {
    padding-top: calc(1rem + var(--web-app-sheet-handle-height));

    .paddingless-child:first-child {
      margin-top: calc(-1rem - var(--web-app-sheet-handle-height));
    }
  }

  &:last-child {
    padding-bottom: 1rem;

    .paddingless-child:last-child {
      margin-bottom: -1rem;
    }
  }
}

.wrapper {
  grid-column: content;
}

.loading-wrapper {
  min-height: 8rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sticky-on-top {
  position: sticky;
  top: var(--sticky-offset);
  z-index: 2;

  &:not(:first-child) {
    top: calc(var(--web-app-sheet-handle-height) + var(--sticky-offset));
  }
}

@media (min-width: $layout-breakpoint) {
  .container:first-child {
    border-radius: 0;
    padding-top: 0;
  }

  .padding {
    &:first-child {
      padding-top: 1rem;
    }

    .paddingless-child {
      width: calc(100% + 2rem);
    }
  }

  .sticky-on-top:not(:first-child) {
    top: var(--sticky-offset);
  }
}
