.root {
  display: grid;
  grid-auto-columns: minmax(0, 1fr);
  grid-auto-flow: column;
  border-radius: var(--border-radius-medium);
  background: var(--neutral-secondary);
  gap: 0.25rem;
  padding: 0.25rem;
}

.selected,
.button {
  border-radius: var(--border-radius-small);
  padding: 0.125rem 0.5rem;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.5;
  text-align: center;
}

.selected {
  background: var(--neutral-primary);
  color: var(--onNeutral-primary);
  cursor: default;
}
