@import '../../shared/scss/mixins';

.map-style-button,
.map-style-button-skeleton {
  border-radius: var(--border-radius-small);
}

.map-style-button-skeleton {
  height: 5.2rem;
}

.map-style-button {
  @include highlighted-outline-color;

  position: relative;
  padding: 0;
  overflow: hidden;
  background: var(--neutral-secondary);
  color: var(--onNeutral-primary);
  border: 1px solid var(--neutral-tertiary);
  font-family: inherit;

  img {
    display: block;
    width: 100%;
    height: 4.3rem;
    -o-object-fit: cover;
       object-fit: cover;
    -o-object-position: center;
       object-position: center;
    transition: opacity var(--transition-short);
  }

  &:not(.map-style-button-active) {
    cursor: pointer;

    &:hover,
    &:focus {
      img {
        opacity: 0.7;
      }
    }
  }
}

.map-style-button-active {
  background: var(--actionColor-primary);
  border-color: var(--actionColor-primary);
  color: var(--onColor-primary);
}

.premium-lock {
  position: absolute;
  top: 0.25rem;
  right: 0.25rem;
  font-size: 0.8rem;
}

.map-style-btn-label {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 0.75rem;
  padding: 0.2rem;
  text-align: left;
  font-weight: var(--font-weight-medium);
}
