@import './definitions.scss';

.container {
  background: var(--neutral-primary);
  padding: 1rem;
  position: var(--web-app-sticky-position);
  bottom: 0;
  inset-inline: 0;
  z-index: $z-index-main-actions;
}

@media (min-width: $layout-breakpoint) {
  .container {
    position: relative;
    grid-area: main-actions;
    border-radius: 0 0 var(--border-radius-large) var(--border-radius-large);
    box-shadow: none;
  }
}
