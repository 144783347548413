.root {
  position: relative;
  display: flex;
  width: 1.5rem;
  height: 1.5rem;
}

.label {
  position: absolute;
  top: 0.3125rem;
  left: 0;
  right: 0;
  text-align: center;
  font-weight: 600;
  font-size: 0.813rem;
  line-height: 0.813rem;
  color: var(--onNeutral-tertiary);
}
