.large {
  position: relative;
  grid-column: 4 / 5;
  grid-row: 2 / 6;
  border-radius: var(--border-radius-large);
  box-shadow: var(--box-shadow-04-dp);
  overflow: hidden;
  z-index: 100;
  background-color: var(--neutral-primary);

  :has(> &) {
    .hidden-if-open {
      display: none !important;
    }
  }
}

.close-button {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
}
