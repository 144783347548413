$border-radius: 1.25rem;

.root {
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: var(--neutral-primary);
  box-shadow: var(--box-shadow-04-dp);
  width: -moz-fit-content;
  width: fit-content;
  border-radius: $border-radius;
  overflow: hidden;

  > :first-child {
    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;
  }

  > :last-child {
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
  }

  > :not(:last-child) {
    border-bottom: 1px solid var(--neutral-secondary);
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  > :not(:first-child) {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
}
