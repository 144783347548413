.root-with-footer {
  max-height: 32.25rem;
}

.content {
  position: relative;
  padding: 0 1rem 1rem;
}

.list {
  margin: 0 0 1rem;
  padding: 0;
}

.entry {
  font-weight: var(--font-weight-medium);
  display: flex;
  padding: 0.5rem 0;
}

.feature-icon {
  color: var(--actionColor-primary);
}

.feature-icon-text {
  font-weight: bold;
  display: inline-block;
  text-align: center;
  width: 1.5rem;
}

.verified-icon {
  margin-top: -0.25rem;
  font-size: 2rem;
}

.label {
  flex-grow: 1;
  margin-left: 0.5rem;
}

.highlighted {
  color: var(--actionColor-primary);
}
