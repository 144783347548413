.root {
  position: relative;
  background-color: var(--neutral-primary);
  padding: 1rem;
  border-radius: 0.5rem;
  box-shadow: var(--box-shadow-base);
  max-width: 25rem;
  overflow-y: auto;
}

.language {
  position: absolute;
  top: 0.9rem;
  right: 1rem;

  span {
    font-weight: var(--font-weight-medium);
    font-size: 1rem;
    vertical-align: 0.1rem;
    color: var(--onNeutral-tertiary);
  }
}

.language-button {
  font-size: 1rem;
  font-weight: var(--font-weight-medium);
}

.explanation {
  background-color: var(--neutral-secondary);
  padding: 0.5rem;
  border-radius: 0.5rem;
  max-height: 13rem;
  overflow-y: auto;

  p {
    line-height: 1.25;
  }
}

.checkboxes {
  margin: 1rem 0;

  > * {
    margin-bottom: 1rem;
  }
}

.requiredCheckbox label {
  flex-direction: row;
}

.requiredAddition {
  color: var(--onNeutral-tertiary);
}

.buttons {
  display: flex;
  gap: 1rem;

  > * {
    flex-grow: 1;
  }
}
