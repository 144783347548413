.container {
  position: relative;
}

.list {
  list-style: none;
  margin: 0;
  padding: 0.5rem 0 1rem;
  display: flex;
  gap: 1rem;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  scrollbar-width: none;
}

.item {
  max-width: calc(100% - 3rem);
  scroll-snap-align: center;
  flex-shrink: 0;

  &:first-child {
    scroll-snap-align: start;
    padding-left: 1rem;
  }

  &:last-child {
    scroll-snap-align: end;
    padding-right: 1rem;
  }
}

.scroll-back-button,
.scroll-button {
  position: absolute;
  bottom: 6.5rem;
  z-index: 1;
  transition: all var(--transition-long) ease;
  box-shadow: var(--box-shadow-base-light);
}

.scroll-back-button {
  left: 0.25rem;
}

.scroll-button {
  right: 0.25rem;
}

.hidden {
  opacity: 0;
  transform: scale(0);
}

@supports (-webkit-touch-callout: none) {
  .scroll-back-button,
  .scroll-button {
    display: none;
  }
}
