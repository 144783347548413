@import './definitions.scss';

.container {
  position: absolute;
  pointer-events: none;
  z-index: $z-index-controls;
  inset: calc(var(--web-app-header-height) + 1rem) 4.25rem auto 1rem;

  > * {
    pointer-events: auto;
  }
}

@media (min-width: $layout-breakpoint) {
  .container {
    position: relative;
    grid-area: top;
    inset: 0;
    align-self: center;
    justify-self: center;
  }
}
