.container {
  height: 100%;
  display: grid;
  grid-template-rows: 1fr auto;
  gap: 0.325em;
}

.route-content {
  margin-left: 0.5em;
  display: grid;
  gap: 0.5em;
  grid-template-columns: 1fr auto;
}

.sidebar {
  grid-column: 2 / 3;
  grid-row: 1 / 4;
}

.route-title {
  font-size: 0.6em;
  font-weight: 500;
  margin: 0;
  line-height: 1.25;
  overflow-wrap: break-word;
  overflow: hidden;
}

.description p {
  font-size: 0.35em;
}
