.button {
  position: relative;
  overflow: hidden;
  border-radius: var(--border-radius-medium);
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 9.5rem 3rem;
  max-width: 23rem;
  text-align: center;
  font-weight: 700;
  font-size: 1rem;
}

.image {
  display: block;
  object-fit: cover;
  pointer-events: none;
  width: 100%;
  height: 9.5rem;
}

.label {
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon {
  display: inherit;
  margin-left: -0.25rem;
  margin-right: 0.25rem;
}
