.root {
  --button-padding-v: 0.5rem;
  --button-padding-h: 0.5rem;
  flex-direction: column;
  align-items: center;
  border-radius: 0.25rem;
}

.with-label {
  width: 4rem;
  min-height: 4rem;
}

.label {
  text-align: center;
  font-weight: var(--font-weight-medium);
  font-size: 0.75rem;
  line-height: 1.25;
  margin-top: 0.4rem;
}
