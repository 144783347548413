.container {
  position: relative;
  max-width: 23rem;
}

.media {
  position: relative;
  height: 12.5rem;
  border-radius: var(--border-radius-medium);
  overflow: hidden;
  margin-bottom: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--onColor-primary);
  background-color: var(--neutral-tertiary);
}

.placeholder-icon {
  font-size: 5rem;
}

.main-image {
  display: block;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}

.tags {
  position: absolute;
  inset: 1rem 1rem auto;
  display: flex;
  gap: 1rem;
  align-items: flex-start;
  justify-content: space-between;
}

.tags-row {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
}

.private-tag {
  justify-self: flex-end;
  margin-left: auto;
}

.avatar {
  position: absolute;
  bottom: 1rem;
  left: 1rem;
  border: 2px solid var(--neutral-primary);
  border-radius: 50%;
  box-shadow: var(--box-shadow-04-dp);
  z-index: 1;
  transition: transform var(--transition-long);

  &:hover {
    transform: scale(1.2);
  }
}

.additional-image {
  position: absolute;
  right: 1rem;
  bottom: 1rem;
  width: 7.25rem;
  height: 4.25rem;
  border: 2px solid var(--neutral-primary);
  border-radius: var(--border-radius-medium);
  box-shadow: var(--box-shadow-04-dp);
}

.heart-button {
  position: relative;
  float: right;
  margin: 0 0 0.5rem 0.5rem;
}

.heart-button-interactive {
  z-index: 1;
}

.title {
  margin-bottom: 0.5rem;
}

a.title {
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;

  &::after {
    content: '';
    position: absolute;
    inset: 0;
    border-radius: var(--border-radius-medium);
  }

  &:focus {
    outline: none;

    &::after {
      outline: solid var(--actionColor-primary);
    }
  }
}

.attributes {
  display: flex;
  flex-wrap: wrap;
  -moz-column-gap: 0.5rem;
       column-gap: 0.5rem;
  font-size: 0.75rem;
  color: var(--onNeutral-secondary);
  list-style: none;
  margin: 0;
}

.attribute-item {
  display: flex;
  gap: 0.25em;

  dt {
    span {
      visibility: hidden;
      display: inline-block;
      width: 0;
    }
  }

  dd {
    margin: 0;
  }
}

.location-attribute {
  width: 100%;
}
