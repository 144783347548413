.container {
  position: absolute;
  inset: 3.5rem 0 0;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: auto;
  color: var(--onColor-primary);
}

.wrapper {
  padding-left: 1rem;
  margin-inline: auto;
  min-width: 0;
  min-height: 0;

  > iframe {
    margin-right: 1rem;
  }
}
