@mixin highlighted-outline-color {
  outline-color: var(--actionColor-secondary);
}

@mixin highlighted-outline {
  @include highlighted-outline-color;
  outline-style: solid;
}

@mixin visually-hidden {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
}

@mixin overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

@mixin field-label {
  font-size: 1rem;
  line-height: 1.5;
  font-weight: var(--font-weight-medium);
}

@mixin anchor {
  @include highlighted-outline-color;
  color: var(--actionColor-onNeutral-primary);
  text-decoration: none;
  font-weight: var(--font-weight-medium);

  &:hover {
    color: var(--actionColor-onNeutral-secondary);
  }
}

@mixin anchor-secondary {
  color: var(--onNeutral-secondary);
}

@mixin anchor-small {
  font-size: 0.75rem;
}

@mixin one-line-truncated-text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
