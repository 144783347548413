.button {
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  -moz-column-gap: 0.5rem;
       column-gap: 0.5rem;
  text-align: left;
  background: none;
  border: none;
  font-family: inherit;
  cursor: pointer;
  outline-color: var(--onColor-primary);
  border-radius: var(--border-radius-small);
  width: 100%;
  padding: 0.5rem;
  color: var(--onColor-primary);
  font-size: 1rem;
  min-height: 3.375rem;

  &:hover {
    background: var(--actionColor-secondary);
  }
}

.with-subtitle :first-child {
  grid-row: 1 / 3;
}

.highlighted {
  background: var(--actionColor-secondary);

  &:hover {
    background: var(--actionColor-tertiary);
  }
}
