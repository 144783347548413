.button {
  font-size: 1rem;
  font-weight: 700;
  border-radius: 1.25rem;
  box-shadow: var(--box-shadow-04-dp);
  padding: 0.5rem 0.75rem;
  font-weight: 500;
}

.icon {
  display: inherit;
  margin-right: 0.25em;
  margin-left: -0.25em;

  svg {
    font-size: 1.5em;
  }
}

.icon-only {
  padding-inline: 0.5rem;
  flex: 0;
}

.icon-only-icon {
  margin: 0;
}
