.illustration {
  width: 100%;
  padding: 1rem 3rem;
  background: var(--neutral-secondary);
  box-sizing: border-box;
  border-radius: 0.5rem;

  svg {
    height: 100%;
    width: auto;
  }
}

.illustration-large {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  background: var(--neutral-secondary);

  svg {
    width: 50vw;
  }
}
