@import '../../shared/scss/mixins';

$container-padding: 1rem;

.container {
  position: relative;
  border-radius: 0.5rem;
  text-align: center;
  background-color: var(--neutral-primary);
}

.default,
.loading {
  border: 1px solid var(--neutral-secondary);
  padding: calc(#{$container-padding} - 1px);
}

.primary:not(.loading) {
  background-color: var(--neutral-secondary);
  padding: $container-padding;
}

.content {
  position: relative;
  margin-top: 0;
  min-height: 2rem;

  &:not(:last-child) {
    margin-bottom: 1rem;
  }
}

.content-grid {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 0.75rem;
}

.invisible-label {
  @include visually-hidden;
}

.label {
  @include field-label;
}

.actions {
  > :first-child:not(:last-child) {
    margin-bottom: 1rem;
  }
}

/* Keep original content during file drag over to preserve the height, just make it invisible*/
.invisible {
  visibility: hidden;
  opacity: 0;
}

/* Show active drag text or loading content above original content*/
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.preview-file {
  position: relative;
  width: 5.5rem;
  aspect-ratio: 1.2;
  flex-grow: 1;
  max-width: min(10rem, 33.333% - 0.5rem);
  border-radius: 0.5rem;
  overflow: hidden;
  border: 1px solid var(--neutral-secondary);
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
}

.preview-file-wide {
  width: 100%;
  max-width: none;
  max-height: 10rem;
}

.delete-button {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  z-index: 1;
}

.preview-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}

.preview-text {
  font-weight: bold;
  text-align: center;
  color: var(--onNeutral-tertiary);
}
