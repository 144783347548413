.container {
  position: relative;
  background: var(--neutral-primary);
  border-radius: var(--border-radius-medium);
  box-shadow: var(--box-shadow-04-dp);
  height: min(25rem, 70vh);
  display: flex;
  flex-direction: column;
}

.close-button-holder {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
}

.header {
  flex-shrink: 0;
  padding: 1rem 1rem 0;
}

.content {
  position: relative;
  overflow-y: auto;
  flex-grow: 1;
  padding: 0 1rem 1rem;
  scroll-timeline: --shadowTimeline y;
  /* Firefox supports the older "vertical" syntax */
  scroll-timeline: --shadowTimeline vertical;
}

@keyframes shadowAnimation {
  from {
    transform: translateY(-1rem);
  }

  5% {
    transform: translateY(-0.25rem);
    height: 1px;
  }

  to {
    transform: translateY(-0.25rem);
    height: 1px;
  }
}

.content::before {
  content: '';
  position: sticky;
  top: 0;
  display: block;
  margin-inline: -1rem;
  box-shadow: 0 0 0.5rem 0 #000;
  isolation: isolate;
  /* Safari */
  transform: translateY(-1rem);
  animation-name: shadowAnimation;
  /* Prevent playing based on time if scroll timeline is not supported */
  animation-duration: 99999999s;
  animation-timeline: --shadowTimeline;
}
