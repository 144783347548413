.container {
  padding: 0.5rem 0.5rem 0.5rem 0.75rem;
  font-size: 0.75rem;
  background-color: var(--neutral-highlight);
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 0.5rem;
  border-radius: var(--border-radius-medium);
  align-items: center;
}

.icon {
  color: var(--actionColor-onNeutral-primary);
}
