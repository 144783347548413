.map-popup {
  max-width: 20rem;

  &:global(.maplibregl-popup) {
    z-index: 2;

    :global(.maplibregl-popup-content) {
      border-radius: var(--border-radius-large);
      background: var(--neutral-primary);
      box-shadow: var(--box-shadow-04-dp);
      padding: 1rem;
    }
  }
}
