.list {
  list-style: none;
  padding: 0;
  display: grid;
  gap: 0.5rem;
  margin-block: 0.5rem;
}

.item {
  border: 1px solid var(--neutral-secondary);
  border-radius: var(--border-radius-medium);
  padding: 0.5rem;
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
}

.map-icon {
  margin: 0 0.5rem -0.4rem;
}
