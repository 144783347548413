.container {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.freehand-illustration {
  border-radius: var(--border-radius-medium);
  overflow: hidden;
}

.freehand-illustration img {
  display: block;
  width: 100%;
  height: auto;
}
