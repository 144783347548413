.container {
  background-color: var(--actionColor-tertiary);
  color: var(--onColor-primary);
  text-transform: uppercase;
  padding: 0.125rem 1.25rem;
  border-radius: 0.875rem;
  display: inline-block;
  white-space: nowrap;
}

.primary {
  background-color: var(--accentColor-tertiary);
}
