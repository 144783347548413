.form-buttons {
  display: flex;
  justify-content: flex-end;
  gap: 0.5rem;
}

.heading {
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 0.5rem;
  align-items: center;
  margin-bottom: 0.5rem;
  min-height: 1.625rem; /* to prevent layout shift when buttons are hidden */
}
