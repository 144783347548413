.header-wrapper {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  padding: 1rem;

  .header {
    width: 100%;
  }
}

.modal {
  height: 100%;
  display: flex;
  flex-direction: column;
  background: var(--neutral-primary);
}

.content {
  padding: 0 1rem 1rem 1rem;
}
