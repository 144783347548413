.container,
.skeleton {
  border-radius: var(--border-radius-medium);
}

.container {
  background-color: var(--neutral-secondary);
  padding: 0.625rem;
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 1rem;
  align-items: start;
  transition: all 0.3s ease;
}

button.container {
  border: none;
  outline: none;
  cursor: pointer;
  font-size: 1rem;
  font-family: inherit;
  line-height: inherit;
  text-align: left;
  width: 100%;

  &:hover {
    background-color: var(--neutral-tertiary);
  }

  &:focus {
    box-shadow: inset 0 0 0 2px var(--actionColor-secondary);
  }

  &:active {
    background-color: var(--neutral-highlight);
  }
}

.selected {
  box-shadow: inset 0 0 0 2px var(--actionColor-primary);
}

.content {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  padding-block: 0.25rem;

  strong {
    font-weight: 500;
  }
}

.name,
.description {
  display: inline-block;
  line-height: 1.25;
}

.price {
  white-space: nowrap;
}

.skeleton {
  width: 100%;
  height: 3rem;
}
