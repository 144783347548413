@import './definitions.scss';

.container {
  position: fixed;
  inset: 0 0 auto;
  z-index: $z-index-header-small;
  background-color: var(--neutral-primary);
  border-bottom: 1px solid var(--neutral-secondary);
  box-shadow: var(--box-shadow-base-light);
  box-sizing: border-box;
  display: flex;
  gap: 0.5rem;
  height: var(--web-app-header-height);
  align-items: center;
  justify-content: space-between;
  padding-inline: 0.75rem 0.5rem;
}

.items {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  align-self: stretch;
}

.extra-items {
  display: none;
}

@media (min-width: $layout-breakpoint) {
  .container {
    position: relative;
    grid-area: header;
    border-top-left-radius: var(--border-radius-large);
    border-top-right-radius: var(--border-radius-large);
    box-shadow: none;
    z-index: $z-index-header-large;
  }
}

@media (min-width: $navigation-breakpoint) {
  .extra-items {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    align-self: stretch;
    padding-right: 0.25rem;
  }
}
