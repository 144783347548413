@import 'shared/ui-components/scss/mixins.scss';

.container {
  @include anchor;

  position: relative;
  display: grid;
  grid-template-areas:
    'avatar name'
    'avatar label';
  grid-template-columns: auto 1fr;
  -moz-column-gap: 0.5rem;
       column-gap: 0.5rem;
  padding: 0.5rem;
  color: var(--onNeutral-primary);
  border-radius: var(--border-radius-medium);
  border-top-left-radius: 2rem;
  border-bottom-left-radius: 2rem;
  margin-left: -0.5rem;

  &:last-child {
    width: 100%;
  }

  &:hover {
    color: var(--onNeutral-secondary);
    background-color: var(--neutral-secondary);
  }

  &:active {
    color: var(--onNeutral-primary);
    background-color: var(--neutral-tertiary);
  }
}

.container > :nth-child(1) {
  grid-area: avatar;
}

.name {
  @include one-line-truncated-text;

  align-self: end;
}
