@import './definitions.scss';

.container {
  position: absolute;
  inset: var(--web-app-header-height) 0 var(--web-app-sheet-minimal-fold);
  transform: translateY(var(--web-app-media-offset));
  transition: transform var(--web-app-media-parallax-transition-time) linear;
  overflow: auto;
}

@media (min-width: $layout-breakpoint) {
  .container {
    position: relative;
    inset: 0;
    grid-column: 1 / 7;
    grid-row: 1 / 7;
    animation: none;
  }
}
