@import 'shared/ui-components/scss/mixins.scss';

.user-container {
  display: grid;
  grid-template-areas:
    'avatar name link'
    'avatar label link';
  grid-template-columns: auto 1fr auto;
  -moz-column-gap: 1rem;
       column-gap: 1rem;

  > :first-child {
    grid-area: avatar;
  }

  > :last-child {
    grid-area: link;
    align-self: center;
  }
}

.user-name {
  @include one-line-truncated-text;

  grid-area: name;
  align-self: end;
}

.user-label {
  @include one-line-truncated-text;

  grid-area: label;
}
