.modal {
  max-height: 39.5rem;
}

.header {
  position: sticky;
  top: 0;
  background-color: var(--neutral-primary);
  padding: 1rem 1rem 0.375rem;
  border-bottom: 1px solid var(--neutral-secondary);
  z-index: 1;
  text-align: center;
}

.buttons {
  display: flex;
  gap: 0.5rem;
  justify-content: space-between;
}
