.image {
  display: block;
  width: 100%;
  height: 13rem;
  -o-object-fit: cover;
     object-fit: cover;
}

.content {
  padding: 2rem 1rem;
  text-align: center;
}

.link-list {
  list-style: none;
  margin: 0;
  padding: 0;

  li {
    display: inline-block;
    margin: 0.5rem;
  }
}

.badge {
  width: 12rem;
  display: block;
}

.image-large {
  display: none;
}

.qr-code {
  display: none;
}

@media (min-width: 60rem) {
  .container {
    position: relative;
    display: grid;
    align-items: center;
    width: 100vw;
    max-width: 143.75rem;
    margin-inline: auto;
  }

  .image {
    display: none;
  }

  .content {
    padding: 5rem 3rem;
  }

  .link-list {
    li {
      margin: 1.5rem;
    }
  }

  .image-large {
    display: block;
    width: 100%;
    height: 37.75rem;
    -o-object-fit: cover;
       object-fit: cover;
  }

  .qr-code {
    display: block;
    width: 9rem;
    padding: 1.5rem;
    margin-bottom: 1rem;
    border-radius: var(--border-radius-medium);
    background-color: var(--neutral-secondary);
  }
}

@media (min-width: 75rem) {
  .container {
    grid-template-columns: 0 1fr 1fr;
  }
}
