.container {
  display: grid;
  gap: 0.5rem;
}

.diagram-container {
  position: relative;
  border-radius: var(--border-radius-medium);
  overflow: hidden;
  height: 1.75rem;
  display: flex;
}

.diagram-area {
  height: 100%;
  background-color: var(--neutral-secondary);
}

.groups-container {
  display: flex;
  gap: 0.25rem;
  justify-content: space-between;
}

.group {
  display: flex;
  gap: 0.25rem;
}

.legend {
  list-style: none;
  margin: 0;
  padding: 0;
  display: grid;
  grid-template-columns: 1fr auto;
}

.legend-item {
  padding-block: 0.125rem;
  display: flex;
  gap: 0.25rem;
  align-items: center;
  grid-column: 1 / 3;
}

.legend-item-before-expand {
  grid-column: 1 / 2;
}

.expand-button-item {
  display: flex;
  align-items: center;
}

.legend-color {
  width: 1.5rem;
  height: 1.5rem;
  border-radius: var(--border-radius-medium);
  background-color: var(--neutral-secondary);
}
