.label {
  font-weight: bold;
}

.description {
  color: var(--onNeutral-secondary);
  transition: color var(--transition-short);
}

.label,
.description {
  font-size: 0.75rem;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}
