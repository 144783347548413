.container {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 0.5rem;

  .plan-item {
    grid-column: span 2;
  }
}

.plan-selection-popover {
  background-color: var(--neutral-primary);
  border-radius: var(--border-radius-medium);
  padding: 1rem;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 0.5rem;
  align-items: center;
}

.plan-selection-list {
  display: grid;
  gap: 0.5rem;
  grid-column: span 2;
  list-style: none;
  margin: 0;
  padding: 0;
}
