.container {
  display: grid;
  grid-template: 100% / 23.5rem 1fr;
  position: absolute;
  inset: 0;
}

.controls {
  background-color: var(--neutral-primary);
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.options,
.code {
  background-color: var(--neutral-secondary);
  padding: 1rem;
  border-radius: var(--border-radius-medium);
}

.code {
  font-size: 0.75rem;
  flex-grow: 1;
  overflow-y: auto;
}

.preview {
  position: relative;
  background-color: #000;
  padding: 1rem;
}

.code {
  code {
    line-break: anywhere;
    overflow-x: hidden;
  }
}
