.marker-dot {
  position: absolute;
  top: 8px;
  left: 8px;
  width: 8px;
  height: 8px;
  background-color: #33647f;
  border-radius: 50%;
}

.marker-dot-large {
  top: 11px;
  left: 14.5px;
  width: 15px;
  height: 15px;
}
