.container {
  position: absolute;
  inset: 0;
  background-color: #000;
  container-type: size;
}

.open-image {
  --lightbox-padding: 3rem;

  display: block;
  position: absolute;
  top: var(--lightbox-padding);
  left: var(--lightbox-padding);
  width: calc(100% - 2 * var(--lightbox-padding));
  height: calc(100% - 2 * var(--lightbox-padding));
  object-fit: contain;

  @container (max-width: 40rem) {
    --lightbox-padding: 1rem;
  }
}

.images-overview-container {
  position: absolute;
  inset-inline: 0;
  padding: 0.5rem 1rem;
  bottom: 1rem;
  overflow-x: auto;
}

.images-overview {
  display: flex;
  justify-content: center;
  gap: 0.25rem;
  width: fit-content;
  margin-inline: auto;
}

.thumbnail {
  display: block;
  border-radius: var(--border-radius-small);
  border: 1px solid #ffffff99;
}

.thumbnail-active {
  border-color: var(--actionColor-primary);
}

.thumbnail-image {
  display: block;
  width: auto;
  height: 3.75rem;
  max-width: 5rem;
  object-fit: cover;
}

.previous-next-buttons-container {
  position: absolute;
  inset: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  pointer-events: none;
}

.previous-next-buttons-container > * {
  pointer-events: all;
}
