.root {
  border-radius: var(--border-radius-medium);
  gap: 2px;
}

.button {
  text-transform: none;
  padding: 1rem;
  border: none;
  background: var(--neutral-secondary);
  color: var(--actionColor-onNeutral-primary);
  font-weight: bold;
  font-size: 1rem;
  line-height: 1;
  height: -moz-fit-content;
  height: fit-content;

  &:first-child {
    border-top-left-radius: var(--border-radius-medium);
    border-bottom-left-radius: var(--border-radius-medium);
  }

  &:last-child {
    border-top-right-radius: var(--border-radius-medium);
    border-bottom-right-radius: var(--border-radius-medium);
  }

  &:hover {
    background: var(--neutral-tertiary);
  }

  &:global(.Mui-selected) {
    &,
    &:hover,
    &:focus {
      background: var(--actionColor-primary);
      color: var(--onColor-primary);
      cursor: default;
      pointer-events: none;
    }
  }
}
