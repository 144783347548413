.location-details {
  margin-bottom: 1rem;
}

.title {
  font-weight: var(--font-weight-medium);
  color: var(--onNeutral-primary);
}

.subtitle {
  font-size: 0.75rem;
  color: var(--onNeutral-secondary);
  padding-top: 0.25rem;
}
