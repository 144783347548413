.container {
  margin: 0 -1rem;
  grid-area: suggestions;
}

.results {
  background: var(--actionColor-primary);
  border-radius: 0 0 var(--border-radius-large) var(--border-radius-large);
  overflow: hidden;
}

.results-separator {
  height: 1rem;
  background-color: var(--neutral-primary);
  border-radius: 0 0 var(--border-radius-large) var(--border-radius-large);
}
