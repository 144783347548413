.container {
  background-color: var(--neutral-primary);
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 2rem;
  box-sizing: border-box;
  outline: none;
  overflow-y: auto;
}
