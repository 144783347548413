.content {
  text-align: center;
  padding: 1rem;
}

.illustration {
  background: var(--neutral-secondary);
  box-sizing: border-box;
  border-radius: 0.5rem;
  padding: 10% 11%;
  margin-bottom: 2rem;
}
