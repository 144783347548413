$height: 3rem;
$height-compact: 2rem;
$border-width: 0.25rem;
$avatar-radius-compact: 2.25rem;

button.root {
  position: relative;
  height: $height;
  font-size: 1rem;
  border-radius: 0.5rem + $border-width;
  border-top-right-radius: $height * 0.5 + $border-width;
  border-bottom-right-radius: $height * 0.5 + $border-width;
  gap: 0.5rem;
  padding-left: 0.5rem;
  border: $border-width solid var(--neutral-primary);
  box-sizing: content-box;
  margin: -$border-width;
}

.avatar {
  border: $border-width solid var(--neutral-primary);
  width: $height;
  height: $height;
  border-radius: $height * 0.5 + $border-width;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: -$border-width;
  background-color: var(--neutral-secondary);
}

button.compact {
  height: $height-compact;
  font-size: 0.75rem;
  gap: 0.25rem;
  padding-left: 0.25rem;

  .avatar {
    width: $avatar-radius-compact;
    height: $avatar-radius-compact;
    border-radius: $avatar-radius-compact * 0.5 + $border-width;
  }
}
