@import '../../shared/scss/mixins';

$padding: calc(0.75rem - 1px);

.root {
  position: relative;
}

.input-wrapper {
  position: relative;
}

.input {
  @include highlighted-outline-color;
  padding: $padding;
  border: 1px solid var(--neutral-secondary);
  border-radius: var(--border-radius-medium);
  transition: border-color var(--transition-short);
  font-size: 1rem;
  box-sizing: border-box;
  width: 100%;
  line-height: var(--line-height-default);
  color: var(--onNeutral-primary);
  resize: none;
  font-family: var(--font-family-default);
  vertical-align: top;
  background-color: var(--neutral-secondary);

  &:hover {
    border-color: var(--neutral-tertiary);
  }

  &:focus {
    border-color: var(--actionColor-primary);
  }

  &::-moz-placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+*/
    color: var(--onNeutral-secondary);
    opacity: 1; /* Firefox*/
  }

  &::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+*/
    color: var(--onNeutral-secondary);
    opacity: 1; /* Firefox*/
  }

  &::-ms-input-placeholder {
    /* Microsoft Edge*/
    color: var(--onNeutral-secondary);
  }
}

.has-error {
  border-color: var(--dangerColor-primary);

  &:hover {
    border-color: var(--dangerColor-secondary);
  }
}

.textarea {
  height: 7.5rem;
}

.resize {
  resize: vertical;
}

.with-clear {
  &.permanent-clear input,
  input:focus {
    padding-right: 3rem;
  }

  input:-moz-placeholder-shown + .clear-button {
    opacity: 0;
    pointer-events: none;
    transition-duration: 0s;
  }

  &:not(.permanent-clear) input:not(:focus) + .clear-button,
  input:placeholder-shown + .clear-button {
    opacity: 0;
    pointer-events: none;
    transition-duration: 0s;
  }
}

.with-icon {
  input {
    padding-left: 2.5rem;
  }
}

.icon-holder {
  position: absolute;
  display: flex;
  left: $padding;
  top: 50%;
  transform: translateY(-50%);
  color: var(--onNeutral-primary);
}

.clear-button {
  position: absolute;
  right: 0.25rem;
  bottom: 0.25rem;
  transition-property: opacity;
  transition-duration: var(--transition-short);
}

.label {
  @include field-label;

  display: block;
  margin-bottom: 0.25rem;
}

.invisible-label {
  @include visually-hidden;
}

.label-required {
  color: var(--dangerColor-onNeutral-primary);
}

.error {
  color: var(--dangerColor-onNeutral-primary);
  line-height: 1.3;
  font-size: 0.75rem;
  padding: 0.25rem 0 0;
}
