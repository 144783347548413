.container {
  padding: 1rem;
  text-align: center;
}

.icon-holder {
  position: relative;
  color: var(--dangerColor-primary);
  display: inline-block;
}

.icon {
  position: absolute;
  font-size: 2rem;
  inset: 0.5rem 0 0;
  margin-inline: auto;
}
