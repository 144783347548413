@import './definitions.scss';

$handle-indicator-height: 0.25rem;

.container {
  border-radius: var(--border-radius-large) var(--border-radius-large) 0 0;
  box-shadow: var(--box-shadow-base-light);
  position: relative;
  z-index: $z-index-sheet;

  &:before {
    content: '';
    position: absolute;
    z-index: $z-index-sheet-handle-indicator;
    left: calc(50% - 1.5rem);
    width: 3rem;
    height: $handle-indicator-height;
    top: calc(var(--web-app-sheet-handle-height) * 0.5 - #{$handle-indicator-height * 0.5});
    border-radius: 0.125rem;
    background: var(--neutral-tertiary);
  }
}

.content {
  display: flex;
  flex-direction: column;
}

.min-height-content {
  min-height: var(--web-app-container-height-sm);
}

.height-filler {
  background-color: var(--neutral-primary);
  flex-grow: 1;
}

.scroll-shadow {
  position: var(--web-app-sticky-position);
  inset-inline: 0;
  bottom: var(--web-app-main-actions-height);
  box-shadow: 0 0 0.5rem #000;
  height: 1px;
  background-color: var(--neutral-secondary);
  margin-inline: -1rem;
  transition: box-shadow var(--transition-short);
}

.scrolled-to-end {
  box-shadow: none;
}

@media (min-width: $layout-breakpoint) {
  .container {
    background-color: var(--neutral-primary);
    grid-area: header / sheet-content / main-actions;
    border-radius: var(--border-radius-large);
    box-shadow: var(--box-shadow-04-dp);
    overflow: hidden;
    display: grid;
    grid-template-columns: subgrid;
    grid-template-rows: subgrid;
    margin-top: 0;

    &:before {
      content: none;
    }
  }

  .content {
    display: block;
    grid-area: sheet-content;
    overflow: hidden auto;
  }

  .height-filler {
    display: none;
  }

  .scroll-shadow {
    bottom: 0;
  }
}
