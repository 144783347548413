@import 'shared/ui-components/scss/mixins.scss';

.popover {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;
  margin-top: 1rem;
  padding: 1rem;
  width: min(20rem, 100vw - 4rem);
  max-height: calc(100vh - 8rem);
  overflow-y: auto;
  background-color: var(--neutral-primary);
  border-radius: var(--border-radius-large);
  color: var(--onNeutral-primary);
}

.group {
  display: grid;
  gap: 0.25rem;
}

.item,
.options-item {
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem;
  transition: all var(--transition-long) ease;
  border-radius: var(--border-radius-medium);
}

.item-with-icon {
  grid-template-columns: auto 1fr auto;
}

.item-chevron {
  opacity: 0;
  transform: scale(0);
  transition: inherit;
}

.item-link {
  @include anchor;
}

.item-link,
.options-item {
  font-weight: bold;

  &:hover,
  &:focus {
    background-color: var(--neutral-secondary);

    .item-chevron {
      opacity: 1;
      transform: none;
    }
  }

  &:active {
    background-color: var(--neutral-tertiary);
  }
}

.item-active {
  background-color: var(--neutral-secondary);
  color: var(--actionColor-onNeutral-primary);
  font-weight: bold;
  cursor: default;
}

.item-danger {
  color: var(--dangerColor-primary);

  &:hover,
  &:focus,
  &:active {
    color: var(--dangerColor-secondary);
  }
}

.options-item {
  color: var(--actionColor-onNeutral-primary);
  font-weight: bold;
  text-align: left;
  font-size: 1rem;
  -webkit-tap-highlight-color: transparent;
  background: transparent;
  border: none;
  cursor: pointer;
}

.option-alternative {
  padding: 0.5rem;
  border-radius: var(--border-radius-large);
}
