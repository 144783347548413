.animated-full-height {
  height: 100%;
}

.modal {
  --modal-width: 23.75rem;

  box-shadow: var(--box-shadow-08-dp);
  border: 0;
  border-radius: var(--border-radius-large);
  background: var(--neutral-primary);
  outline: none;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.modal:not(.full-screen) {
  position: relative;
  top: 50%;
  left: 50%;
  max-width: min(calc(100% - 2rem), var(--modal-width));
  max-height: calc(100% - 2rem);
  transform: translate(-50%, -50%);
}

.full-screen {
  position: absolute;
  inset: 0.5rem;
}

.dismiss-button {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  z-index: 10;
}

.content {
  position: relative;
  overflow-y: auto;
  flex-grow: 1;
}

.buttons-container {
  box-shadow: var(--box-shadow-04-dp);
  padding: 1rem;
  z-index: 1;
}
