$chip-height: 2.25rem;
$chip-font-size: 1rem;
$chip-padding-inline: 1rem;
$chip-padding-block: ($chip-height - $chip-font-size) * 0.5;
$chip-icon-size: 1.5rem;
$chip-icon-padding: ($chip-height - $chip-icon-size) * 0.5;

.chip {
  position: relative;
  display: inline-block;
  font-size: $chip-font-size;
  line-height: 1;
  padding: $chip-padding-block $chip-padding-inline;
  border-radius: $chip-height * 0.5;
  background-color: var(--neutral-secondary);
  color: var(--onNeutral-primary);
  font-weight: 500;
  border: none;
  font-family: inherit;
  white-space: nowrap;
}

a.chip {
  text-decoration: none;
}

button.chip {
  cursor: pointer;
}

a.chip,
button.chip {
  &:hover,
  &:focus {
    color: var(--onNeutral-secondary);
  }

  &:active {
    background-color: var(--neutral-tertiary);
  }
}

.with-icon {
  padding-left: $chip-height + 0.5rem;
}

.icon-holder {
  position: absolute;
  top: 0;
  left: 0;
  display: inline-block;
  background-color: var(--neutral-tertiary);
  border-radius: $chip-height * 0.5;
  padding: $chip-icon-padding;
  line-height: 0;
}

.active {
  &:not(.with-icon) {
    background-color: var(--actionColor-primary);
    color: var(--onColor-primary);
  }

  .icon-holder {
    background-color: var(--actionColor-primary);
    color: var(--onColor-primary);
  }
}

.skeleton {
  width: 7rem;
  height: $chip-height;
  border-radius: $chip-height * 0.5;
}
