.container {
  display: inline-block;
  width: 1.75em;
  height: 1.75em;
  text-align: center;

  > svg {
    height: 1.75em;
    vertical-align: -0.4em;
    fill: var(--actionColor-onNeutral-primary);
  }
}

.on-color > svg {
  fill: var(--onColor-primary);
}
