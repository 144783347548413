.root {
  overflow: hidden;
  overflow-wrap: break-word;
  word-break: break-word;
}

.truncated {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: var(--lines-limit);
  line-clamp: var(--lines-limit);
  -webkit-box-orient: vertical;
}

@supports (hanging-punctuation: first) and (-webkit-appearance: none) {
  /* target Safari */
  .truncated > :not(:first-child) {
    display: none;
  }
}
