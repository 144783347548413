@import '../../shared/scss/mixins';

.root {
  @include highlighted-outline-color;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  background: transparent;
  border: 0;
  margin: 0;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  text-decoration: none;
  font-family: inherit;
  line-height: 1.5;
  padding: var(--button-padding-v) var(--button-padding-h);
  transition-property: color, background-color, border-color;
  transition-duration: var(--transition-short);

  &:hover {
    text-decoration: none;
  }
}

.disabled {
  cursor: default;
}

.covering-click-target {
  position: static;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

/*
Variants
*/

.primary {
  background-color: var(--actionColor-primary);
  color: var(--onColor-primary);

  &:not(.disabled) {
    &:hover {
      background-color: var(--actionColor-secondary);
    }

    &:active {
      background-color: var(--actionColor-tertiary);
    }
  }

  &.disabled {
    background-color: var(--neutral-tertiary);
    color: var(--actionColor-onNeutral-inactive);
  }
}

.secondary {
  background-color: var(--neutral-secondary);
  color: var(--actionColor-onNeutral-primary);

  &:not(.disabled) {
    &:hover {
      background-color: var(--neutral-tertiary);
      color: var(--actionColor-onNeutral-secondary);
    }

    &:active {
      background-color: var(--neutral-quartery);
      color: var(--actionColor-onNeutral-tertiary);
    }
  }

  &.disabled {
    background-color: var(--neutral-secondary);
    color: var(--actionColor-onNeutral-inactive);
  }
}

.accent {
  background-color: var(--accentColor-primary);
  color: var(--onColor-primary);

  &:not(.disabled) {
    &:hover {
      background-color: var(--accentColor-secondary);
    }

    &:active {
      background-color: var(--accentColor-tertiary);
    }
  }

  &.disabled {
    background-color: var(--neutral-tertiary);
    color: var(--accentColor-onNeutral-inactive);
  }
}

.danger-primary {
  background-color: var(--dangerColor-primary);
  color: var(--onColor-primary);

  &:not(.disabled) {
    &:hover {
      background-color: var(--dangerColor-secondary);
    }

    &:active {
      background-color: var(--dangerColor-tertiary);
    }
  }

  &.disabled {
    background-color: var(--neutral-tertiary);
    color: var(--dangerColor-onNeutral-inactive);
  }
}

.danger-secondary {
  background-color: var(--neutral-secondary);
  color: var(--dangerColor-onNeutral-primary);

  &:not(.disabled) {
    &:hover {
      background-color: var(--neutral-tertiary);
      color: var(--dangerColor-onNeutral-secondary);
    }

    &:active {
      background-color: var(--neutral-quartery);
      color: var(--dangerColor-onNeutral-tertiary);
    }
  }

  &.disabled {
    background-color: var(--neutral-secondary);
    color: var(--dangerColor-onNeutral-inactive);
  }
}

.ghost-primary {
  color: var(--actionColor-onNeutral-primary);

  &:not(.disabled) {
    &:hover {
      color: var(--actionColor-onNeutral-secondary);
      background-color: var(--neutral-secondary);
    }

    &:active {
      color: var(--actionColor-onNeutral-tertiary);
      background-color: var(--neutral-tertiary);
    }
  }

  &.disabled {
    color: var(--actionColor-onNeutral-inactive);
  }
}

.ghost-secondary {
  color: var(--onNeutral-primary);

  &:not(.disabled) {
    &:hover {
      color: var(--onNeutral-secondary);
      background-color: var(--neutral-secondary);
    }

    &:active {
      color: var(--onNeutral-primary);
      background-color: var(--neutral-tertiary);
    }
  }

  &.disabled {
    color: var(--actionColor-onNeutral-inactive);
  }
}

.ghost-danger {
  color: var(--dangerColor-onNeutral-primary);

  &:not(.disabled) {
    &:hover {
      color: var(--dangerColor-onNeutral-secondary);
      background-color: var(--neutral-secondary);
    }

    &:active {
      color: var(--dangerColor-onNeutral-tertiary);
      background-color: var(--neutral-tertiary);
    }
  }

  &.disabled {
    color: var(--dangerColor-onNeutral-inactive);
  }
}

.onColor-primary {
  background-color: var(--onColor-primary);
  color: var(--button-onColor-base-color);

  &:not(.disabled) {
    &:hover {
      background-color: var(--onColor-secondary);
    }

    &:active {
      background-color: var(--onColor-tertiary);
    }
  }

  &.disabled {
    background-color: var(--onColor-tertiary);
  }
}

.onColor-secondary {
  color: var(--onColor-primary);
  border: 1px solid var(--onColor-primary);
  padding: calc(var(--button-padding-v) - 1px) calc(var(--button-padding-h) - 1px);

  &:not(.disabled) {
    &:hover {
      color: var(--onColor-secondary);
      border-color: var(--onColor-secondary);
    }

    &:active {
      color: var(--onColor-primary);
      border-color: var(--onColor-tertiary);
    }
  }

  &.disabled {
    color: var(--onColor-tertiary);
    border-color: var(--onColor-tertiary);
  }
}

.onColor-ghost {
  color: var(--onColor-primary);

  &:not(.disabled) {
    &:hover {
      color: var(--onColor-secondary);
    }

    &:active {
      background-color: var(--onColor-tertiary);
    }
  }

  &.disabled {
    color: var(--onColor-tertiary);
  }
}
