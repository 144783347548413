.container {
  display: grid;
  border-radius: var(--border-radius-medium);
  overflow: hidden;
  height: 12.5rem;
  grid-template-columns: 2fr 1fr;
  grid-template-rows: repeat(2, 1fr);
  gap: 0.25rem;
}

.container-full {
  grid-template-rows: repeat(3, 1fr);
}

.image-item {
  position: relative;
  display: block;
  min-height: 0;
}

.image-item:first-child {
  grid-column-start: 1;
  grid-row: 1 / -1;
}

.image-item:not(:first-child) {
  grid-column: 2 / 3;
}

.image-item:last-child {
  grid-column-end: -1;
  grid-row-end: -1;
}

.image-item:nth-child(2) {
  grid-row-start: 1;
}

.image {
  display: block;
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.dimmed-item::before {
  content: '';
  position: absolute;
  inset: 0;
  background-color: var(--onNeutral-primary);
  opacity: 0.5;
}

.overlay-label {
  position: absolute;
  inset: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--onColor-primary);
}
