$z-index-border: 1;

.root {
  position: relative;
  text-align: center;
  color: var(--onNeutral-secondary);
  margin: 2rem 0;
  line-height: 1;
  font-size: 1rem;
  min-height: 1rem;

  &::before {
    content: '';
    border-bottom: 1px solid var(--neutral-secondary);
    position: absolute;
    top: 50%;
    right: 0;
    left: 0;
    z-index: $z-index-border;
  }
}

.content {
  position: relative;
  z-index: $z-index-border + 1;
  background-color: var(--neutral-primary);
  padding: 0 0.5rem;
}
