.container {
  position: relative;
}

.image {
  display: block;
  width: 100%;
  height: 12.5rem;
  object-fit: cover;
  margin-bottom: 0.25rem;
}

.edit-button-holder {
  position: absolute;
  top: 1rem;
  right: 1rem;
}

.tour-tag {
  position: absolute;
  top: 1rem;
  left: 1rem;
}
