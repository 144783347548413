.container {
  display: grid;
  gap: 0.5rem 0.25rem;
}

.item {
  display: grid;
  grid-template-areas:
    'icon value'
    'icon label';
  grid-template-columns: auto 1fr;
  column-gap: 0.5rem;
  align-self: center;
}

.item > :nth-child(1) {
  grid-area: icon;
  align-self: center;
  font-size: 1.75rem;
}

.change-indicator {
  display: inline-block;
  width: 0.3rem;
  height: 0.3rem;
  border-radius: 0.15rem;
  background-color: var(--actionColor-primary);
  margin: 0 0.25rem 0.1rem;
}
