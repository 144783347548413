.container {
  position: relative;
}

.divider {
  display: flex;
  position: absolute;
  left: 1rem;
  bottom: -1rem;
  color: var(--neutral-tertiary);
}

.waypoint-icon {
  color: var(--onNeutral-tertiary);
  padding: 0.5rem 0 0.5rem 0.5rem;
  line-height: 0;
  margin-left: -0.5rem;
}

.delete-button {
  margin-right: -0.5rem;
}
