@import '../../shared/scss/mixins';

.select {
  .select-trigger {
    height: 1.5rem;
  }

  :global(.MuiOutlinedInput-notchedOutline) {
    border-radius: var(--border-radius-medium);
    border-color: transparent;
  }

  .onColor {
    background-color: var(--neutral-primary);
  }

  .open {
    background: var(--actionColor-primary);
    color: var(--onColor-primary);
    box-shadow: var(--box-shadow-04-dp);

    &,
    &:focus {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  :global(.MuiSelect-icon) {
    color: var(--actionColor-onNeutral-primary);
  }

  :global(.MuiSelect-iconOpen) {
    color: var(--onColor-primary);
  }
}

@media (hover: hover) {
  .select {
    &:hover {
      :global(.MuiOutlinedInput-notchedOutline) {
        border-color: var(--neutral-tertiary);
        border-width: 1px;
      }
    }
  }
}

.select-trigger {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  background: var(--neutral-secondary);
  color: var(--actionColor-onNeutral-primary);
  font-weight: bold;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  padding-left: 1rem;

  &,
  &:focus {
    border-radius: var(--border-radius-medium);
  }
}

.menu {
  > :global(.MuiMenu-paper) {
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    border-bottom-right-radius: var(--border-radius-medium);
    border-bottom-left-radius: var(--border-radius-medium);
    box-shadow: var(--box-shadow-04-dp);

    > ul {
      padding: 0;
    }
  }
}

.select-item {
  padding: 0.75rem 1rem;
  color: var(--actionColor-onNeutral-primary);
  font-weight: bold;
  display: flex;
  align-items: center;
  gap: 0.5rem;

  &,
  &:focus {
    background: var(--neutral-secondary);
  }

  &:not(:last-child) {
    margin-bottom: 1px;
  }

  &:hover {
    background: var(--neutral-primary);
  }

  &:global(.Mui-selected) {
    background: var(--actionColor-primary);
    color: var(--onColor-primary);
  }
}

.select-item-label {
  flex-grow: 1;
}

.label {
  @include field-label;

  margin-bottom: 0.25rem;
}

.invisible-label {
  @include visually-hidden;
}
