@keyframes active_animation {
  from {
    transform: none;
    opacity: 0;
  }
  50% {
    opacity: 0.3;
  }
  to {
    transform: scale(25);
    opacity: 0;
  }
}

.button {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: var(--actionColor-onNeutral-primary);
}

.button:hover {
  color: var(--actionColor-onNeutral-secondary);
}

.button:active {
  color: var(--actionColor-onNeutral-tertiary);
}

.active::before {
  content: '';
  position: absolute;
  top: 0.75em;
  left: calc(50% - 1px);
  width: 2px;
  height: 2px;
  border-radius: 50%;
  background-color: var(--actionColor-primary);
  opacity: 0;
  animation: active_animation 0.8s ease-out;
}
