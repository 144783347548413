@import '../../shared/scss/mixins';

$slider-spacing-inline: 0.5rem;

.container {
  display: grid;
  grid-template-columns: 1fr auto;
  grid-template-areas:
    'label value'
    'slider slider'
    'markers markers';
  -moz-column-gap: 0.5rem;
       column-gap: 0.5rem;
  align-items: center;
}

.label {
  @include field-label;

  grid-area: label;
}

.value {
  grid-area: value;
  font-size: 0.75rem;
}

.slider-holder {
  grid-area: slider;
  padding: 0.5rem $slider-spacing-inline 0;
}

.markers {
  grid-area: markers;
  position: relative;
  height: 1rem;
  margin-inline: $slider-spacing-inline;
}

.marker {
  position: absolute;
  transform: translateX(-50%);
  font-size: 0.75rem;
  font-weight: 500;
  white-space: nowrap;

  &:first-child {
    transform: translateX(-$slider-spacing-inline);
  }

  &:last-child {
    transform: translateX(-100%) translateX($slider-spacing-inline);
  }
}
