$layout-breakpoint: 48rem;
$navigation-breakpoint: 65rem;
$height-breakpoint: 35rem;

$z-index-controls: 2;
$z-index-sheet: 3;
$z-index-header-small: $z-index-sheet; // Keeping header shadow beloy sheet
$z-index-footer: $z-index-sheet; // Keeping sheet shadow below footer
$z-index-main-actions: 4;
$z-index-header-large: 5;
$z-index-sheet-handle-indicator: 10;
$z-index-scroll-tooltip: $z-index-sheet-handle-indicator; // To be still on top of indicator
