.container {
  display: grid;
  gap: 1rem;
}

.creator-row {
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  gap: 0.5rem;
}
