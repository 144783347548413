.options-form {
  margin-top: 1rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;

  > :first-child,
  > :nth-child(2) {
    grid-column: 1 / 3;
  }
}
