.root {
  display: grid;
  grid-template-rows: 1fr auto;
  height: 100%;
}

.map-container {
  border: 1px solid var(--onNeutral-secondary);
  border-radius: 0.25em;
  overflow: hidden;
  box-sizing: border-box;
}

.print-image {
  display: block;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}

.preview-map {
  height: 100%;
}

.attributions {
  font-size: 0.2em;
  line-height: 1.25;
  text-align: right;
  margin-top: 0.5em;
  margin-right: 2.5em;

  &,
  a {
    color: var(--onNeutral-primary);
    text-decoration: none;
    text-shadow: 0.05em 0.05em 0.1em var(--neutral-primary);
  }

  a {
    display: inline-block;
  }
}
